<template>
  <b-sidebar
    id="app-channel-config-sidebar"
    :visible="isAppChannelFeeListSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-app-channel-fee-list-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          APP Channel Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form class="p-2">

        <!-- Name -->
        <b-form-group
          label="Name(Customizable)"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="feeInfo.feeName"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Pay Party -->
        <b-form-group
          label="Pay Party"
          label-for="pay-party"
        >
          <v-select
            v-model="payParty"
            input-id="pay-party"
            :options="payPartyOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
          />
        </b-form-group>

        <!-- Fee Type -->
        <b-form-group
          label="Fee Type"
          label-for="fee-type"
        >
          <v-select
            v-model="feeInfo.feeType"
            input-id="fee-type"
            :options="feeTypeFilter"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
          />
        </b-form-group>

        <!-- Lower Bound -->
        <b-form-group
          label="Lower Bound (Set -1 to mark as no-limit)"
          label-for="lower-bound"
        >
          <b-form-input
            id="lower-bound"
            v-model="feeInfo.lowerBound"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Upper Bound -->
        <b-form-group
          label="Upper Bound (Set -1 to mark as no-limit)"
          label-for="upper-bound"
        >
          <b-form-input
            id="upper-bound"
            v-model="feeInfo.upperBound"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <div v-if="feeInfo.feeType === '103' || feeInfo.feeType === '203' || feeInfo.feeType === '301'">
          <b-form-group
            label="Installment Stage Fee"
          >
            <b-input-group
              v-for="(item, index) in installmentList"
              :key="index"
              :prepend="`Stage ${item.label} :`"
              append="%"
              class="px-1 mb-1"
            >
              <b-form-input
                v-model="item.value"
                type="number"
                autofocus
                trim
              />
            </b-input-group>
          </b-form-group>
        </div>

        <div v-else>
          <!-- Value -->
          <b-form-group
            label="Value"
            label-for="setting-value"
          >
            <b-form-input
              id="setting-value"
              v-model="feeInfo.settingValue"
              type="number"
              autofocus
              trim
            />
          </b-form-group>

          <!-- Unit -->
          <b-form-group
            label="Unit"
            label-for="unit"
          >
            <v-select
              v-model="feeUnit"
              input-id="unitsetting"
              :options="currencyOptions"
              :clearable="false"
              :searchable="false"
            />
          </b-form-group>
        </div>

        <!-- Lower Limit -->
        <b-form-group
          v-if="payParty === '200' && feeUnit === '%'"
          label="Lower Limit (Set -1 to mark as no-limit)"
          label-for="lower-limit"
        >
          <b-form-input
            id="lower-limit"
            v-model="feeInfo.lowerLimit"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Upper Limit -->
        <b-form-group
          v-if="payParty === '200' && feeUnit === '%'"
          label="Upper Limit (Set -1 to mark as no-limit)"
          label-for="upper-limit"
        >
          <b-form-input
            id="upper-limit"
            v-model="feeInfo.upperLimit"
            type="number"
            autofocus
            trim
          />
        </b-form-group>

        <!-- Charge Type -->
        <b-form-group
          v-if="payParty === '200'"
          label="Charge Type"
          label-for="charge-type"
        >
          <v-select
            v-model="feeChargeType"
            :options="chargeTypeOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="charge-type"
          />
        </b-form-group>

        <!-- Status -->
        <b-form-group
          label="Status"
          label-for="app-status"
        >
          <v-select
            v-model="feeInfo.status"
            :options="statusOptions"
            :reduce="val => val.value"
            :clearable="false"
            :searchable="false"
            input-id="app-status"
          />
        </b-form-group>

        <el-divider class="mt-3 mb-3" />

        <div v-if="$can('write', 'Merchants.Fee')" class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="isSubmitDisabled"
            @click.prevent="validationForm"
          >
            Submit
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BFormGroup, BInputGroup, BFormInput, BButton, BForm,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useMerchantsJs from './merchants'

const {
  updateAppChannelFee,
  fetchCommonOptions,
} = useMerchantsJs()

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppChannelFeeListSidebarActive',
    event: 'update:is-app-channel-fee-list-sidebar-active',
  },
  props: {
    isAppChannelFeeListSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      feeInfo: {
        isNew: true,
        appId: '',
        channelId: '',
        lowerLimit: '',
        upperLimit: '',
        lowerBound: '',
        upperBound: '',
        settingValue: '',
        configDetail: '',
        feeName: '',
        feeType: '',
        index: '',
        payParty: '',
        status: '',
      },
      feeUnit: '',
      feeChargeType: '',
      payParty: '',

      chargeTypeOptions: [
        { label: 'Once', value: '1' },
        { label: 'Full', value: '2' },
        { label: 'Percentage', value: '3' },
      ],

      statusOptions: [
        { label: 'Disable', value: '0' },
        { label: 'Enable', value: '1' },
      ],

      payPartyOptions: [
        { label: 'User', value: '100' },
        { label: 'Merchant', value: '200' },
        { label: 'Platform', value: '300' },
        { label: 'Bank', value: '400' },
      ],

      feeTypeFilter: [],
      feeTypeOptions: [
        { label: 'Default Fee', value: '100' },
        { label: 'Tax Fee', value: '101' },
        { label: 'Bank Fee', value: '102' },
        { label: 'Installment Fee', value: '103' },
        { label: 'Country Fee', value: '104' },
        { label: 'Interest Fee', value: '105' },

        { label: 'Default Fee', value: '200' },
        { label: 'Processing Fee', value: '201' },
        { label: 'Refund Fee', value: '204' },
        { label: 'Chargeback Fee', value: '205' },
        { label: 'AVS Fee', value: '206' },
        { label: 'Tax Fee', value: '207' },
        { label: 'Reserve Fund', value: '208' },

        { label: 'Default Fee', value: '300' },
        { label: 'Installment Fee', value: '301' },

        { label: 'Default Fee', value: '400' },
        { label: 'Tax Fee', value: '401' },
      ],

      installmentList: [
        { label: '01', value: '' },
        { label: '02', value: '' },
        { label: '03', value: '' },
        { label: '04', value: '' },
        { label: '05', value: '' },
        { label: '06', value: '' },
        { label: '07', value: '' },
        { label: '08', value: '' },
        { label: '09', value: '' },
        { label: '10', value: '' },
        { label: '11', value: '' },
        { label: '12', value: '' },
      ],

      currencyOptions: [],
    }
  },
  computed: {
    isSubmitDisabled() {
      let isValueSetted = true
      if (this.feeInfo.feeType === '103' || this.feeInfo.feeType === '203' || this.feeInfo.feeType === '301') {
        this.installmentList.forEach(item => {
          if (item.value === '') {
            isValueSetted = false
          }
        })
      } else if (this.feeInfo.settingValue === '' || this.feeUnit === '') {
        isValueSetted = false
      }
      if (this.feeInfo.payParty === '200' && (!this.feeChargeType || this.feeChargeType === '')) {
        isValueSetted = false
      }
      return this.feeInfo.feeName === '' || this.feeInfo.payParty === '' || this.feeInfo.feeType === ''
        || this.feeInfo.lowerBound === '' || this.feeInfo.upperBound === '' || this.feeInfo.status === ''
        || !isValueSetted
    },
  },
  watch: {
    isAppChannelFeeListSidebarActive(val) {
      if (!val) {
        return
      }
      this.initAppChannelFee(this.data)
    },
    payParty(val) {
      this.feeInfo.payParty = val
      this.feeTypeFilter = this.feeTypeOptions.filter(ft => parseInt(ft.value / 100, 0) * 100 === parseInt(val, 0))
      if (parseInt(this.feeInfo.feeType / 100, 0) * 100 !== parseInt(val, 0)) {
        this.feeInfo.feeType = val
      }
    },
  },
  created() {
    fetchCommonOptions(
      options => {
        const { currencies } = options

        currencies.forEach(item => {
          this.currencyOptions.push(item.code)
        })
        this.currencyOptions.push('%')
      },
      fail => {
        showAlert(this, 'warning', 'Fail!', `Fetch options fail with ${fail}`)
      },
    )
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    initAppChannelFee(info) {
      if (info.is_new) {
        this.feeInfo.isNew = true
        this.feeInfo.appId = info.app_id
        this.feeInfo.region = info.region
        this.feeInfo.method = info.method
        this.feeInfo.channel = info.channel
        this.feeInfo.channelId = info.channel_id
        return
      }
      this.feeInfo = {
        isNew: false,
        appId: info.app_id,
        region: info.region,
        method: info.method,
        channel: info.channel,
        channelId: info.channel_id,
        feeName: info.fee_name,
        feeType: info.fee_type,
        index: info.index,
        payParty: info.pay_party,
        status: info.status,
      }
      this.payParty = info.pay_party
      this.setDetail(info.config_detail)
    },
    initValues() {
      this.feeInfo = {
        isNew: true,
        appId: '',
        region: '',
        method: '',
        channel: '',
        channelId: '',
        lowerBound: '',
        upperBound: '',
        settingValue: '',
        unit: '',
        configDetail: '',
        feeName: '',
        feeType: '',
        index: '',
        payParty: '',
        status: '',
      }
      this.payParty = ''
    },
    setDetail(details) {
      [this.feeInfo.lowerBound, this.feeInfo.upperBound, this.feeInfo.settingValue, this.feeUnit, this.feeInfo.lowerLimit, this.feeInfo.upperLimit, this.feeChargeType] = details.split(',')
      if (this.feeInfo.lowerBound === '~') {
        this.feeInfo.lowerBound = -1
      }
      if (this.feeInfo.upperBound === '~') {
        this.feeInfo.upperBound = -1
      }
      if (this.feeInfo.lowerLimit === '~') {
        this.feeInfo.lowerLimit = -1
      }
      if (this.feeInfo.upperLimit === '~') {
        this.feeInfo.upperLimit = -1
      }

      if (this.feeInfo.feeType === '103' || this.feeInfo.feeType === '203' || this.feeInfo.feeType === '301') {
        const [, , ...stage] = details.split(',')
        this.installmentList.forEach((item, i) => {
          this.installmentList[i].value = stage[i]
        })
      } else {
        [, , this.feeInfo.settingValue, this.feeUnit] = details.split(',')
      }
    },
    validationForm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.update()
        }
      })
    },
    update() {
      let lowerBound = ''
      let upperBound = ''
      let value = ''
      let unit = ''
      let lowerLimit = ''
      let upperLimit = ''
      if (this.feeInfo.lowerBound < 0) {
        lowerBound = '~'
      } else {
        lowerBound = this.feeInfo.lowerBound
      }
      if (this.feeInfo.upperBound < 0) {
        upperBound = '~'
      } else {
        upperBound = this.feeInfo.upperBound
      }
      if (!this.feeInfo.lowerLimit || this.feeInfo.lowerLimit < 0 || this.feeInfo.lowerLimit === '') {
        lowerLimit = '~'
      } else {
        lowerLimit = this.feeInfo.lowerLimit
      }
      if (!this.feeInfo.upperLimit || this.feeInfo.upperLimit < 0 || this.feeInfo.upperLimit === '') {
        upperLimit = '~'
      } else {
        upperLimit = this.feeInfo.upperLimit
      }
      if (this.feeInfo.feeType === '103' || this.feeInfo.feeType === '203' || this.feeInfo.feeType === '301') {
        const stages = []
        this.installmentList.forEach((item, i) => {
          stages.push(this.installmentList[i].value)
        })
        value = stages.join(',')
        unit = '%'
      } else {
        value = this.feeInfo.settingValue
        unit = this.feeUnit
      }
      const details = []
      details.push(lowerBound)
      details.push(upperBound)
      details.push(value)
      details.push(unit)
      if (this.feeInfo.payParty === '200') {
        details.push(lowerLimit)
        details.push(upperLimit)
        details.push(this.feeChargeType)
      }
      const detailString = details.join(',')

      const params = {
        app_id: this.feeInfo.appId,
        region: this.feeInfo.region,
        method: this.feeInfo.method,
        channel: this.feeInfo.channel,
        channel_id: this.feeInfo.channelId,
        index: this.feeInfo.index,
        fee_name: this.feeInfo.feeName,
        fee_type: this.feeInfo.feeType,
        pay_party: this.feeInfo.payParty,
        config_detail: detailString,
        status: this.feeInfo.status,
      }
      updateAppChannelFee(
        params,
        () => {
          this.showAlert('success', 'Success!', 'updated.')
          this.$emit('update:is-app-channel-fee-list-sidebar-active', false)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
