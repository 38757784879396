<template>
  <div>
    <!-- Add New Sidebar -->
    <app-channel-fee-list-sidebar
      :is-app-channel-fee-list-sidebar-active.sync="isAppChannelFeeListSidebarActive"
      :data="feeInfo"
      @updated="queryChannelFees"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-button
          variant="outline-primary"
          :to="{ name: 'merchant-app-channels', query: {merchant_no: merchantNo, app_id: appId}, params: { merchant_name: merchantName, app_name: appName } }"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back</span>
        </b-button>

        <b-button
          v-if="$can('write', 'Merchants.Fee')"
          class="ml-2"
          variant="outline-primary"
          @click="addNewChannelFee"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </b-button>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="fees"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <!-- Column: Fee Type -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block">
              {{ data.item.fee_name }}
            </span>
            <small class="text-muted">@{{ resolveFeeType(data.item.fee_type) }}</small>
          </b-media>
        </template>

        <!-- Column: Pay Party -->
        <template #cell(pay_party)="data">
          {{ resolvePayParty(data.item.pay_party) }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="showDetail(data.item)"
            />

            <feather-icon
              v-if="$can('write', 'Merchants.Fee')"
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer mx-1"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BMedia, BBadge,
} from 'bootstrap-vue'
import useMerchantsJs from './merchants'
import AppChannelFeeListSidebar from './AppChannelFeeListSidebar.vue'

const {
  fetchAppChannelFees,
  deleteAppChannelFee,
} = useMerchantsJs()

export default {
  components: {
    AppChannelFeeListSidebar,

    BCard,
    BTable,
    BButton,
    BMedia,
    BBadge,
  },
  data() {
    return {
      merchantNo: this.$route.query.merchant_no,
      merchantName: this.$route.params.merchant_name,
      appId: this.$route.query.app_id,
      appName: this.$route.params.app_name,
      channelId: this.$route.query.channel_id,
      channelName: this.$route.params.channel_name,
      region: this.$route.query.region,
      method: this.$route.query.method,
      channel: this.$route.query.channel,
      fees: [],
      tableFields: [
        'name',
        { key: 'pay_party', label: 'PAY PARTY' },
        { key: 'create_time', label: 'REGISTER TIME' },
        { key: 'status', label: 'STATUS' },
        'actions',
      ],

      isAppChannelFeeListSidebarActive: false,
      feeInfo: {},
    }
  },
  created() {
    this.queryChannelFees()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    queryChannelFees() {
      const params = {
        app_id: this.appId,
        region: this.region,
        method: this.method,
        channel: this.channel,
      }
      fetchAppChannelFees(
        params,
        feeList => {
          this.fees = feeList
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveFeeType(feeType) {
      if (feeType === '100') return 'Default Fee'
      if (feeType === '101') return 'Tax Fee'
      if (feeType === '102') return 'Bank Fee'
      if (feeType === '103') return 'Installment Fee'
      if (feeType === '104') return 'Country Fee'
      if (feeType === '105') return 'Interest Fee'

      if (feeType === '200') return 'Default Fee'
      if (feeType === '201') return 'Processing Fee'
      if (feeType === '204') return 'Refund Fee'
      if (feeType === '205') return 'Chargeback Fee'
      if (feeType === '206') return 'AVS Fee'
      if (feeType === '207') return 'Tax Fee'
      if (feeType === '208') return 'Reserve Fund'

      if (feeType === '300') return 'Default Fee'
      if (feeType === '301') return 'Installment Fee'

      if (feeType === '400') return 'Default Fee'
      if (feeType === '401') return 'Tax Fee'

      return 'Unknown'
    },
    resolvePayParty(val) {
      const payParty = parseInt(val / 100, 0)
      if (payParty === 1) return 'User'
      if (payParty === 2) return 'Merchant'
      if (payParty === 3) return 'Platform'
      if (payParty === 4) return 'Bank'
      return 'Unknown'
    },
    resolveStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === '0') return 'Disabled'
      if (status === '1') return 'Enabled'
      return 'Unknown'
    },
    addNewChannelFee() {
      this.feeInfo = {
        is_new: true,
        app_id: this.appId,
        region: this.region,
        method: this.method,
        channel: this.channel,
      }
      this.isAppChannelFeeListSidebarActive = true
    },
    showDetail(channelDetail) {
      this.feeInfo = channelDetail
      this.feeInfo.is_new = false
      this.isAppChannelFeeListSidebarActive = true
    },
    deleteConfirm(feeDetail) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteFee(feeDetail)
        }
      })
    },
    deleteFee(feeDetail) {
      deleteAppChannelFee(
        feeDetail,
        () => {
          this.showAlert('success', 'Success!', 'Deleted.')
          this.queryChannelFees()
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
